import React from "react";
import { Link } from "gatsby";
import { Col, Button } from "react-bootstrap";
import styled from "styled-components";

const StyledBlockButton = styled(Button)`
    
    border: thin solid #797979;

    & .imgWrapper {
        min-width: 50px;
        min-height: 50px;
        background-color: black;
    }
    & img {
        max-width: 32px;
    }
`

const BlockButton = ({img, text, customClasses, ...rest}) => {
    
    return (
        <StyledBlockButton
            {...rest}
            variant="secondary"
            size="lg"
            className={`${customClasses} d-flex align-items-center p-0 text-left w-100 mb-7`}
        >
            <div className="imgWrapper d-flex align-items-center justify-content-center"><img src={ img } /></div>
            <div className="button-text text-left px-7">{ text }</div>
        </StyledBlockButton>
    )
}

export default BlockButton