import React from "react";
import { graphql, Link, navigate } from "gatsby";
import { Container, Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import SEO from "../components/seo";
import { useContent } from "../hooks/useContent";
import BackButton from "../components/Core/BackButton"
import { Entities } from "../components/Entities";
import { 
    useMsal,  
    useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../msal/authConfig";
import BackgroundImage from 'gatsby-background-image';
import LogoAnthos from "../assets/image/logo_anthos_square_white.svg"
import LogoCofra from "../assets/image/logo_cofra_square_white.svg"
import LogoSunrock from "../assets/image/logo_sunrock_square_white.svg"
import LogoAfam from "../assets/image/logo_afam_square_white.svg"
import BlockButton from "../components/Core/ButtonBlock"

const Content = styled.div`
    background-color: #FFECC6;
    min-height: 100vh;
`

const RulesList = styled.ul`
  list-style: none;
  padding: 0;

  & li p {
    font-size: 1.5rem;
  }
`

const SignIn = ({data, location}) => {
    
    const headerConfig = {
        render: false,
    };

    const footerConfig = {
        render: false,
    }

    const content = useContent()
    
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();

    // if signed in, navigate to default page
    if (inProgress === InteractionStatus.None && isAuthenticated) {
        navigate("/search")
    }

    const handleSignin = (policy) => {
        if (inProgress === InteractionStatus.None && !isAuthenticated) {
            instance.loginRedirect(loginRequest(policy));
        }
    }

    const imageData = data.desktop.childImageSharp.fluid

    const logos = {
        anthos: LogoAnthos,
        cofra: LogoCofra,
        afam: LogoAfam,
        sunrock: LogoSunrock
    }

    return (
        <>  
            <PageWrapper headerConfig={headerConfig} footerConfig={footerConfig}>
                <SEO title="Sign in" />
                <Content>
                    <Container fluid className="justify-content-left">
                        <Row>
                            <Col className="col-12 col-lg-6 col-xl-4 pl-10 vh-100 overflow-auto">
                                <Container>
                                    <h1 className="mt-10 mb-2 font-family-primary font-size-7">
                                        { content.get('portal-title', true) }
                                    </h1>
                                </Container>
                                
                                <BackButton text="Homepage" customLink="/"/>

                                <Container className="mt-6">
                                    <h2 className="font-family-primary text-uppercase font-size-7">Sign In</h2>
                                    <p>{ content.get('use-your-company-account', true) }</p>
                                    <Row className="mt-10">
                                        <div className="col-12 col-md-11 col-sm-8">
                                            { Entities.map((e)=>(
                                                e.active && 
                                                <BlockButton 
                                                    text={e.name}
                                                    img={logos[e.label]}
                                                    onClick={() => handleSignin(e.b2cPolicy)}
                                                    customClasses={`_gtm_login_button ${e.label}`}
                                                />
                                            )) }
                                        </div>
                                    </Row>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-single-sign-on`}>
                                                { content.get('tooltip-we-use-single-sign-on', true) }
                                            </Tooltip>
                                        }
                                        >
                                        <div className="mb-10 mt-7">
                                            <p className="font-size-4">
                                                { content.get('why-do-you-need-my-account', true) }
                                            </p>
                                        </div>
                                    </OverlayTrigger>
                                    <Button variant="link" className="text-black pl-0 text-left">
                                        <Link to="/cookies-and-privacy" className="">
                                            <i class="fa fa-user-shield font-size-7 mr-6"></i>
                                            { content.get('see-privacy-statement', true) }
                                        </Link>
                                    </Button>
                                </Container>
                            </Col>
                            <Col className="d-xl-flex align-items-center d-none col-lg-6 col-xl-8 m-0 p-0">
                                <BackgroundImage
                                    Tag="div"
                                    className="position-absolute top-0"
                                    fluid={imageData}
                                    duotone={{opacity: 50}}
                                    backgroundColor="white"
                                    style={{minHeight: '100vh', minWidth: '100%', zIndex: 0}}
                                    >
                                </BackgroundImage>
                                <Row
                                    className="ml-20"
                                    style={{zIndex: 10}}
                                >
                                    <Col className="col-12">
                                        <h1 className="font-size-9 mb-3">
                                            {content.get('sign-in-motivation-text-heading', true)}
                                        </h1>
                                        <RulesList className="check-rules">
                                            <li className="pb-7 text-left font-size-7">
                                                <Row className="pl-7">
                                                <Col className="col-auto px-0 font-size-8">✓</Col>
                                                <Col className="col-10">
                                                    { content.get('sign-in-motivation-text-first-point', false)}
                                                </Col>
                                                </Row>
                                            </li>
                                            <li className="pb-7 text-left font-size-7">
                                                <Row className="pl-7">
                                                <Col className="col-auto px-0 font-size-8">✓</Col>
                                                <Col className="col-10">
                                                { content.get('sign-in-motivation-text-second-point', false)}
                                                </Col>
                                                </Row>
                                            </li>
                                            <li className="pb-7 text-left font-size-7">
                                                <Row className="pl-7">
                                                <Col className="col-auto px-0 font-size-8">✓</Col>
                                                <Col className="col-10">
                                                { content.get('sign-in-motivation-text-third-point', false)}
                                                </Col>
                                                </Row>
                                            </li>
                                        </RulesList>
                                    </Col>
                                </Row>                                
                            </Col>
                        </Row>
                    </Container>
                </Content>
            </PageWrapper>
        </>
    )
}

export default SignIn;

export const query = graphql`
    {
        desktop: file(relativePath: { eq: "photo-two-people-talk_opacity_20.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`